import React from "react";
import "../styles/Header.css";
import { FaHome, FaBell, FaUser, FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import useUser from "../config/useUser";

const Header = () => {


  const {user, isLoading} = useUser();


  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <header className="header">
      <Link  className="logo" to="/">MySite</Link>
      <nav className="nav-menu">
        <ul>
          <li>
            <Link className="a" to="/">
              <FaHome className="icon" />
              <p className="menutitle">Home</p>
            </Link>
          </li>
          <li>
            <Link className="a" to="/notifications">
              <FaBell className="icon" />
              <p className="menutitle">Notifications</p>
            </Link>
          </li>
         {user?.subscription === "admin" && <li>
            <Link className="a" to="/addpost">
              <FaPlusCircle className="icon" />
              <p className="menutitle">Add Post</p>
            </Link>
          </li>}
          <li>
            <Link className="a" to={!user ? "/login" : "/profile"}>
              <FaUser className="icon" />
              <p className="menutitle">Profile</p>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
