// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1RSbnrxayfG5bambRBo2fZ_En6vqXl70",
  authDomain: "trade-428d7.firebaseapp.com",
  projectId: "trade-428d7",
  storageBucket: "trade-428d7.firebasestorage.app",
  messagingSenderId: "220996143168",
  appId: "1:220996143168:web:7b9638d5909d4cf03a77a5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);