import React from 'react';
import "../styles/Home.css";
import Ads from '../components/Home/Ads';
import Posts from '../components/Home/Posts';

export default function Home() {
  return (
    <div className='Home'>
        <Ads />
        <Posts />
        <Ads />
    </div>
  )
}
