import React, { useEffect, useState } from "react";
import "../../styles/Posts.css";
import Post from "./Post";
import { collection, query, orderBy, limit, onSnapshot, getFirestore } from "firebase/firestore";
import {app} from "../../Firebase";

export default function Posts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = () => {
      setLoading(true);

      const db = getFirestore(app);
      // Requête en temps réel
      const q = query(
        collection(db, "posts"), // Nom de la collection
        orderBy("createdAt", "desc"), // Trier par `createdAt`
        limit(20) // Limiter à 20 posts
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(data);
        setLoading(false);
      });

      // Nettoyage lors du démontage du composant
      return () => unsubscribe();
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <p>Chargement...</p>;
  }

  return (
    <div className="Posts">
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
    </div>
  );
}
