import React from "react";
import "../../styles/Post.css";
import { FaThumbsUp, FaThumbsDown, FaCommentDots } from "react-icons/fa";
import { FaChartLine, FaClock, FaArrowDown, FaArrowUp } from "react-icons/fa";
import { doc, updateDoc, arrayUnion, arrayRemove, increment, getFirestore } from "firebase/firestore";
import { app } from "../../Firebase"; // Assurez-vous que Firestore est configuré
import useUser from "../../config/useUser";

export default function Post({ post }) {
  const { user } = useUser();

  const db = getFirestore(app);

  // Gestion du Like
  const handleLike = async () => {
    if (!user) return alert("Vous devez être connecté pour liker !");
    const postRef = doc(db, "posts", post.id);
    const userRef = doc(db, "users", user.id);

    try {
      if (post.like.includes(user.id)) {
        // Supprimer l'utilisateur du tableau `like`
        await updateDoc(postRef, {
          like: arrayRemove(user.id),
        });
        // Décrémenter le champ `votes` dans le document utilisateur
        await updateDoc(userRef, {
          votes: increment(-1),
        });
      } else {
        // Ajouter l'utilisateur au tableau `like` et le supprimer de `dislike`
        await updateDoc(postRef, {
          like: arrayUnion(user.id),
          dislike: arrayRemove(user.id), // Empêcher un double vote
        });
        // Incrémenter le champ `votes` dans le document utilisateur
        await updateDoc(userRef, {
          votes: increment(1),
        });
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du like :", error);
    }
  };

  // Gestion du Dislike
  const handleDislike = async () => {
    if (!user) return alert("Vous devez être connecté pour disliker !");
    const postRef = doc(db, "posts", post.id);
    const userRef = doc(db, "users", user.id);

    try {
      if (post.dislike.includes(user.id)) {
        // Supprimer l'utilisateur du tableau `dislike`
        await updateDoc(postRef, {
          dislike: arrayRemove(user.id),
        });
        // Décrémenter le champ `votes` dans le document utilisateur
        await updateDoc(userRef, {
          votes: increment(-1),
        });
      } else {
        // Ajouter l'utilisateur au tableau `dislike` et le supprimer de `like`
        await updateDoc(postRef, {
          dislike: arrayUnion(user.id),
          like: arrayRemove(user.id), // Empêcher un double vote
        });
        // Incrémenter le champ `votes` dans le document utilisateur
        await updateDoc(userRef, {
          votes: increment(1),
        });
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du dislike :", error);
    }
  };

  return (
    <div className="Post">
      {/* Header */}
      <div className="post-header">
        <img
          src="https://botnation.ai/site/wp-content/uploads/2024/01/chatbot-drupal.webp"
          alt="profile"
          className="profile-image"
        />
        <div className="header-info">
          <h2 className="profile-name">Nanivid</h2>
          <p className="post-date">
            Add at {new Date(post.createdAt?.seconds * 1000).toLocaleString()}
          </p>
        </div>
      </div>

      {/* Body */}
      <div className="post-body">
        {/* Rectangle gauche */}
        <div
          style={
            post.type === "BUY"
              ? { backgroundColor: "blue" }
              : { backgroundColor: "red" }
          }
          className="tag-rectangle"
        >
          <p className="vertical-text">{post.type}</p>
        </div>

        {/* Contenu des news */}
        <div className="news-content">
          <h2>
            <FaChartLine style={{ marginRight: 10, color: "goldenrod" }} />
            Instruments:{" "}
            <span style={{ color: "crimson" }}>{post.instrument}</span>
          </h2>
          <hr style={{ border: "1px solid #ddd", margin: "10px 0" }} />
          <div className="price-info">
            <p>
              <strong>Price: </strong>
              <span style={{ color: "blue", fontSize: "18px" }}>
                {post.price}
              </span>
            </p>
            <p>
              <FaArrowUp style={{ color: "green", marginRight: 10 }} />
              TP1:{" "}
              <span style={{ color: "green", fontWeight: "bold" }}>
                {post.tp1}
              </span>
            </p>
            <p>
              <FaArrowUp style={{ color: "green", marginRight: 10 }} />
              TP2:{" "}
              <span style={{ color: "green", fontWeight: "bold" }}>
                {post.tp2}
              </span>
            </p>
            <p>
              <FaArrowUp style={{ color: "green", marginRight: 10 }} />
              TP3:{" "}
              <span style={{ color: "green", fontWeight: "bold" }}>
                {post.tp3}
              </span>
            </p>
            <p>
              <FaArrowDown style={{ color: "red", marginRight: 10 }} />
              Stop Loss:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {post.stopLost}
              </span>
            </p>
          </div>
          <div className="timeframe">
            <FaClock style={{ color: "crimson", marginRight: 10 }} />
            Timeframe:{" "}
            <strong style={{ color: "crimson" }}>{post.timeframe}</strong>
          </div>
          <div className="timeframe">
            <FaCommentDots style={{ color: "crimson", marginRight: 10 }} />
            Commentaire:{" "}
            <strong style={{ color: "crimson" }}>{post.commentaire}</strong>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="post-footer">
        <button className="footer-btn" onClick={handleLike}>
          <FaThumbsUp /> {post.like.length} Good Analysis
        </button>
        <button className="footer-btn" onClick={handleDislike}>
          <FaThumbsDown /> {post.dislike.length} False Analysis
        </button>
        <button
          onClick={() => alert("Bientôt disponible !")}
          className="footer-btn"
        >
          <FaCommentDots /> 0 Comments
        </button>
      </div>
    </div>
  );
}
