import React, { useState } from 'react';
import "../styles/AddPost.css";
import { getFirestore, addDoc, collection, serverTimestamp } from 'firebase/firestore'; // Import Firestore modules
import { app } from '../Firebase';

export default function AddPost() {
  const [formData, setFormData] = useState({
    instrument: '',
    price: '',
    tp1: '',
    tp2: '',
    tp3: '',
    stopLoss: '',
    commentaire: '',
    timeframe: '',
    type: 'BUY',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      like:[],
      dislike: [],
      createdAt: serverTimestamp()
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const db = getFirestore(app);
    const postsCollectionRef = collection(db, 'posts'); // Reference to the 'posts' collection

    try {
      // Add the post data to Firestore
      await addDoc(postsCollectionRef, formData);
      alert('Post ajouté avec succès!');
      // Reset the form or handle success in another way
      setFormData({
        instrument: '',
        price: '',
        tp1: '',
        tp2: '',
        tp3: '',
        commentaire: '',
        stopLoss: '',
        timeframe: '',
        type: 'BUY',
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout du post :", error);
      // Handle errors (e.g., display an error message)
    }
  };

    return (
        <div className='AddPost'>
            <h2>Ajouter un Post</h2>
            <form onSubmit={handleSubmit} className="add-post-form">
                <div className="form-group">
                    <label>Instrument</label>
                    <input
                        type="text"
                        name="instrument"
                        value={formData.instrument}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Prix</label>
                    <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>TP 1</label>
                    <input
                        type="number"
                        name="tp1"
                        value={formData.tp1}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>TP 2</label>
                    <input
                        type="number"
                        name="tp2"
                        value={formData.tp2}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>TP 3</label>
                    <input
                        type="number"
                        name="tp3"
                        value={formData.tp3}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Stop Loss</label>
                    <input
                        type="number"
                        name="stopLoss"
                        value={formData.stopLoss}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Timeframe</label>
                    <input
                        type="text"
                        name="timeframe"
                        value={formData.timeframe}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Commentaire</label>
                    <input
                        type="text"
                        name="commentaire"
                        value={formData.commentaire}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Type</label>
                    <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        required
                    >
                        <option value="BUY">BUY</option>
                        <option value="SELL">SELL</option>
                    </select>
                </div>
                <button type="submit" className="submit-btn">Ajouter</button>
            </form>
        </div>
    );
}
