import React from 'react';
import { FaBell, FaCheckCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';
import "../styles/Notifications.css";

export default function Notifications() {
    const notifications = [
        { id: 1, type: "success", message: "Web site is working cool !" },
        { id: 2, type: "error", message: "No Error" },
        { id: 3, type: "info", message: "System updated !" },
    ];

    const getIcon = (type) => {
        switch (type) {
            case "success":
                return <FaCheckCircle style={{ color: "green", marginRight: 10 }} />;
            case "error":
                return <FaExclamationCircle style={{ color: "red", marginRight: 10 }} />;
            case "info":
                return <FaInfoCircle style={{ color: "blue", marginRight: 10 }} />;
            default:
                return <FaBell style={{ color: "gray", marginRight: 10 }} />;
        }
    };

    return (
        <div className="notifications-container">
            <h2>
                <FaBell style={{ marginRight: 10, color: "crimson" }} />
                Notifications
            </h2>
            <ul className="notifications-list">
                {notifications.map((notif) => (
                    <li key={notif.id} className={`notification-item ${notif.type}`}>
                        {getIcon(notif.type)}
                        {notif.message}
                    </li>
                ))}
            </ul>
        </div>
    );
}
