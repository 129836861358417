import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import "../styles/NotFound.css";

export default function NotFound() {
    return (
        <div className="notfound-container">
            <FaExclamationTriangle style={{ fontSize: "50px", color: "crimson", marginBottom: 20 }} />
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist or has been moved.</p>
            <button className="home-button" onClick={() => (window.location.href = "/")}>
                Go Back to Home
            </button>
        </div>
    );
}
