import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import AddPost from './pages/AddPost';
import Notifications from './pages/Notifications';
import Login from './pages/Login';
import Register from './pages/Register';
import NotFound from './pages/NotFound';
import Profile from './pages/Profile';
import useUser from './config/useUser';

function App() {
  // Variable pour simuler l'utilisateur connecté
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // PrivateRoute pour protéger certaines routes
  const PrivateRoute = ({ element }) => {
    return user ? element : <Navigate to="/login" />;
  };

  // PublicRoute pour interdire l'accès aux pages login et register si l'utilisateur est déjà connecté
  const PublicRoute = ({ element }) => {
    return !user ? element : <Navigate to="/" />;
  };

  return (
    <Router>
      <Header />
      <Routes>
        {/* Routes publiques - uniquement accessibles si l'utilisateur n'est pas connecté */}
        <Route path="/login" element={<PublicRoute element={<Login />} />} />
        <Route path="/register" element={<PublicRoute element={<Register />} />} />
        <Route path="/notifications" element={<Notifications />} />

        {/* Routes privées - nécessite une connexion */}
        <Route path="/" element={<PrivateRoute element={<Home />} />} />
        <Route path="/addpost" element={<PrivateRoute element={<AddPost />} />} />
        <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />

        {/* Route "Not Found" pour les liens inexistants */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
