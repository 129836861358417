import { getAuth, signOut } from "firebase/auth";
import React from "react";
import { app } from "../Firebase";
import useUser from "../config/useUser";

export default function Profile() {

  const {user, isLoading} = useUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Fonction de déconnexion
  const handleLogout = () => {
    const auth = getAuth(app);
    signOut(auth)
      .then(() => {
        // Déconnexion réussie
        alert("user logout");
        // Redirigez l'utilisateur vers la page de connexion ou une autre page appropriée
      })
      .catch((error) => {
        // Erreur lors de la déconnexion
        console.error("Erreur lors de la déconnexion :", error);
      });
  };

  return (
    <div className="profile-page" style={styles.profilePage}>
      <div style={styles.profileHeader}>
        <img
          src="https://thumbs.dreamstime.com/b/businessman-counts-money-pop-art-avatar-character-icon-round-retro-vector-illustration-98244852.jpg" // Image placeholder
          alt="User Avatar"
          style={styles.avatar}
        />
        <h1>{user.username}</h1>
        <p>{user.email}</p>
      </div>

      <div style={styles.statsSection}>
        <div style={styles.statsBox}>
          <h3>Comments</h3>
          <p>{user.commentsCount}</p>
        </div>

        <div style={styles.statsBox}>
          <h3>Votes</h3>
          <p>{user.votes}</p>
        </div>

        <div style={styles.statsBox}>
          <h3>Subscription</h3>
          <p>{user.subscription}</p>
        </div>
      </div>

      <button onClick={handleLogout} style={styles.logoutButton}>
        Log Out
      </button>
    </div>
  );
}

// Styles en ligne pour un design moderne
const styles = {
  profilePage: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#fff",
  },
  profileHeader: {
    marginBottom: "30px",
  },
  avatar: {
    borderRadius: "50%",
    width: "150px",
    height: "150px",
    objectFit: "cover",
    marginBottom: "10px",
  },
  statsSection: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "30px",
  },
  statsBox: {
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "8px",
    width: "150px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  logoutButton: {
    backgroundColor: "#d9534f",
    color: "white",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
    transition: "background-color 0.3s ease",
  },
};
